import { Component, Vue, Inject } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { IReport } from '@/shared/model/report.model';

import AlertService from '@/shared/alert/alert.service';
import ReportService from '@/entities/report/report.service';
import { ISummaryReport } from '@/shared/model/summary-report.model';
import { ITEMS_PER_PAGE } from '@/constants';
import AuthService from '@/account/auth.service';

@Component({
  mixins: [Vue2Filters.mixin],
  filters: {
    formatShortDate: (date: Date | null) => {
      if (!date) {
        return '';
      }
      return new Date(date).toLocaleDateString('de-DE');
    },
  },
})
export default class Report extends Vue {
  public itemsPerPage = ITEMS_PER_PAGE;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public totalItems = 0;
  public reports: ISummaryReport[] = [];
  public isAdmin = false;
  public isFetching = false;
  @Inject('reportService') private reportService: () => ReportService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('authService') private authService: () => AuthService;
  private removeId: number = null;

  public async mounted(): Promise<void> {
    await this.retrieveAllReports();
    this.isAdmin = await this.authService().isAdmin();
  }

  public async clear(): Promise<void> {
    this.page = 1;
    await this.retrieveAllReports();
  }

  public async retrieveAllReports(): Promise<void> {
    this.isFetching = true;
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: [],
    };
    try {
      const res = await this.reportService().retrieveAllSummaryReportsPreview(paginationQuery);

      this.reports = res.data;
      this.totalItems = Number(res.headers['x-total-count']);
      this.queryCount = this.totalItems;
      this.isFetching = false;
    } catch (e) {
      const msg = 'Fehler beim Laden der Einträge.';
      this.alertService().showCustomServerError(this, msg, e);
    } finally {
      this.isFetching = false;
    }
  }

  /**
   * returns customer names for given summary report
   * @param sumReport
   */
  public customers(sumReport: ISummaryReport): string {
    return sumReport.reports
      .map(report => `${report.customerLastName} ${report.customerFirstName}`)
      .join(', ');
  }

  public prepareRemove(instance: IReport): void {
    this.removeId = instance.id;
    if (this.$refs.removeEntity as any) {
      (this.$refs.removeEntity as any).show();
    }
  }

  public async removeReport(): Promise<void> {
    try {
      await this.reportService().deleteSummaryReport(this.removeId);
      this.alertService().showSuccess(this, 'Der Eintrag wurde erfolgreich gelöscht.');
      this.removeId = null;
      await this.retrieveAllReports();
      this.closeDialog();
    } catch (e) {
      const msg = 'Fehler beim Löschen des Eintrags.';
      this.alertService().showCustomServerError(this, msg, e);
    }
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveAllReports().then();
  }

  public closeDialog(): void {
    (this.$refs.removeEntity as any).hide();
  }
}
