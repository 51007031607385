var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "boxed-layout" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between align-items-baseline mb-3",
        },
        [
          _c("h2", { attrs: { id: "page-heading" } }, [_vm._v("Berichte")]),
          _vm._v(" "),
          _c("router-link", {
            attrs: { to: { name: "ReportCustomerSelection" }, custom: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ navigate }) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary create-entity create-report",
                        attrs: { id: "create-entity" },
                        on: { click: navigate },
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v(" Neuen Bericht erstellen ")]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.isFetching && _vm.reports && _vm.reports.length === 0
        ? _c("div", { staticClass: "alert alert-warning" }, [
            _c("span", [_vm._v("Es wurden keine Berichte gefunden.")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reports && _vm.reports.length > 0
        ? _c("div", [
            _c(
              "table",
              {
                staticClass: "table table-striped",
                attrs: { "aria-describedby": "reports" },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.reports, function (report) {
                    return _c(
                      "tr",
                      { key: report.id, attrs: { "data-cy": "entityTable" } },
                      [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatShortDate")(report.createdTimestamp)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.customers(report)))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            staticStyle: { "min-width": "150px" },
                          },
                          [
                            _c("router-link", {
                              attrs: {
                                to: {
                                  name: "ReportDetails",
                                  params: { reportId: report.id },
                                },
                                custom: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ navigate }) {
                                      return [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-primary edit",
                                            attrs: {
                                              "data-cy": "entityEditButton",
                                            },
                                            on: { click: navigate },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-md-inline",
                                              },
                                              [_vm._v("Details")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _vm.isAdmin
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.removeEntity",
                                        modifiers: { removeEntity: true },
                                      },
                                    ],
                                    staticClass: "btn btn-sm",
                                    on: {
                                      click: function ($event) {
                                        return _vm.prepareRemove(report)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "trash" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row justify-content-center" },
              [
                _c("b-pagination", {
                  attrs: {
                    size: "md",
                    "total-rows": _vm.totalItems,
                    "per-page": _vm.itemsPerPage,
                    change: _vm.loadPage(_vm.page),
                  },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("small", [
                _vm._v(
                  " Es sind insgesamt " +
                    _vm._s(_vm.totalItems) +
                    " Berichte vorhanden."
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", { ref: "removeEntity", attrs: { id: "removeEntity" } }, [
        _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
          _c(
            "span",
            {
              attrs: {
                id: "cdpRisikoApp.report.delete.question",
                "data-cy": "reportDeleteDialogHeading",
              },
            },
            [_vm._v("Löschen bestätigen")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", { attrs: { id: "gen-delete-report-heading" } }, [
            _vm._v(
              "\n        Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [_vm._v("Nicht Löschen")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                type: "button",
                id: "gen-confirm-delete-report",
                "data-cy": "entityConfirmDeleteButton",
              },
              on: {
                click: function ($event) {
                  return _vm.removeReport()
                },
              },
            },
            [_vm._v("\n        Löschen\n      ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Datum")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Kunden")]),
        _vm._v(" "),
        _c("th", {
          staticStyle: { "min-width": "150px" },
          attrs: { scope: "row" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }